<template>
  <div>

    <div v-if="messageType === 'club-event'">
      <i class="tim-icons icon-tie-bow" style="font-size: 39px;"></i>
      <p><strong style="font-size: 1.1rem">Would you like to join us?</strong></p>
      <hr>

      <p
        style="color: #fff !important; margin-top: -10px;width: 300px;margin-left: auto;margin-right: auto;left: 0;right: 0;">
        The perfect option for those seeking <strong>upscale experiences.</strong> Ensure your spot, don't miss it out.<br>
      </p>
    </div>

    <div v-if="messageType === 'club-party'">
      <img v-lazy="astroImg+'?h=30'" height="30px" width="30px" alt="tulum party support"
           style="margin-left: auto;margin-right: auto;left: 0;right: 0;margin-top: 20px;">
      <p><strong style="font-size: 1.3rem">INFO & RSVP</strong></p>
      <hr>
      <p
        style="color: #fff !important; margin-top: -10px;width: 300px;margin-left: auto;margin-right: auto;left: 0;right: 0;">
        <strong>Have the party of your dreams with our exclusive tables & seats.</strong>  Need more info? Drop us a line and we will be happy to help.

      </p>
      <hr>
    </div>


    <div v-if="messageType === 'party'">


      <div style="border: 5px solid black;background-color: black;border-radius: 500px">
        <img v-lazy="imgNft+'?h=90'" height="90px" alt="vip backstage experience nfts" width="135px">
        <p><strong>💥 Party Like a Boss </strong></p>
      </div>
      <hr>
      <p
        style="color: #fff !important; margin-top: -10px;width: 300px;margin-left: auto;margin-right: auto;left: 0;right: 0;">
        <strong>Elevate your party experience to the next level</strong> with our premium seating options, including tables
        and VIP areas. <br> Get in touch with us to discover more and secure your spot today.
      </p>
    </div>
    <hr>

    <Reservations v-bind:show-brands="false" :club="slug"></Reservations>
    <small style="margin-top: -10px"
    >We operate from 9AM to 10PM / Answer within 30 mins</small>
    <br/>
    <h5 style="font-size: 0.7rem">PLAN A | <strong>SIDE B</strong></h5>
  </div>

</template>
<script>
import Reservations from '../../components/Reservations/Reservations';

export default {
  name: 'EventReservations',
  components: {Reservations},
  props: ['slug'],

  data: () => {
    return {
      imgNft: 'https://imgix.cosmicjs.com/d690f420-80a0-11ed-8730-d9eebcd39d9f-nft.jpg',
      astroImg: 'https://imgix.cosmicjs.com/6ed6b0f0-60cd-11ee-b3cd-9debaa756aee-image.png',
    };
  },
  computed: {
    messageType: function () {
      if (this.slug.match(/crania/)) {
        return 'party'
      }
      if (this.slug.match(/bagatelle|tantra|taboo|rosa-negra/)) {
        return 'club-event'
      }
      return 'club-party'
    }

  },
  methods: {}
};
</script>



