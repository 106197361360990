var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.messageType === 'club-event')?_c('div',[_c('i',{staticClass:"tim-icons icon-tie-bow",staticStyle:{"font-size":"39px"}}),_vm._m(0),_c('hr'),_vm._m(1)]):_vm._e(),(_vm.messageType === 'club-party')?_c('div',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.astroImg+'?h=30'),expression:"astroImg+'?h=30'"}],staticStyle:{"margin-left":"auto","margin-right":"auto","left":"0","right":"0","margin-top":"20px"},attrs:{"height":"30px","width":"30px","alt":"tulum party support"}}),_vm._m(2),_c('hr'),_vm._m(3),_c('hr')]):_vm._e(),(_vm.messageType === 'party')?_c('div',[_c('div',{staticStyle:{"border":"5px solid black","background-color":"black","border-radius":"500px"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.imgNft+'?h=90'),expression:"imgNft+'?h=90'"}],attrs:{"height":"90px","alt":"vip backstage experience nfts","width":"135px"}}),_vm._m(4)]),_c('hr'),_vm._m(5)]):_vm._e(),_c('hr'),_c('Reservations',{attrs:{"show-brands":false,"club":_vm.slug}}),_c('small',{staticStyle:{"margin-top":"-10px"}},[_vm._v("We operate from 9AM to 10PM / Answer within 30 mins")]),_c('br'),_vm._m(6)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',{staticStyle:{"font-size":"1.1rem"}},[_vm._v("Would you like to join us?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"color":"#fff !important","margin-top":"-10px","width":"300px","margin-left":"auto","margin-right":"auto","left":"0","right":"0"}},[_vm._v(" The perfect option for those seeking "),_c('strong',[_vm._v("upscale experiences.")]),_vm._v(" Ensure your spot, don't miss it out."),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',{staticStyle:{"font-size":"1.3rem"}},[_vm._v("INFO & RSVP")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"color":"#fff !important","margin-top":"-10px","width":"300px","margin-left":"auto","margin-right":"auto","left":"0","right":"0"}},[_c('strong',[_vm._v("Have the party of your dreams with our exclusive tables & seats.")]),_vm._v(" Need more info? Drop us a line and we will be happy to help. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("💥 Party Like a Boss ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"color":"#fff !important","margin-top":"-10px","width":"300px","margin-left":"auto","margin-right":"auto","left":"0","right":"0"}},[_c('strong',[_vm._v("Elevate your party experience to the next level")]),_vm._v(" with our premium seating options, including tables and VIP areas. "),_c('br'),_vm._v(" Get in touch with us to discover more and secure your spot today. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticStyle:{"font-size":"0.7rem"}},[_vm._v("PLAN A | "),_c('strong',[_vm._v("SIDE B")])])
}]

export { render, staticRenderFns }